import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "title"
};
var _hoisted_2 = {
  class: "grape-item"
};
var _hoisted_3 = {
  class: "grape-item"
};
import { ref, reactive, onMounted, nextTick } from 'vue';
export default {
  __name: 'AddOperateSignatureModal',
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '簽名'
    }
  },
  emits: ['confirm', 'update:visible'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var cData = reactive({
      signatureBoard: ''
    });

    function confirm() {
      emit('confirm', cData.signatureBoard.getDataURL());
    }

    return function (_ctx, _cache) {
      var _component_SignatureBoard = _resolveComponent("SignatureBoard");

      var _component_BaseButton = _resolveComponent("BaseButton");

      var _component_BaseGrape = _resolveComponent("BaseGrape");

      var _component_MModal = _resolveComponent("MModal");

      return _openBlock(), _createBlock(_component_MModal, {
        "dismissable-mask": "",
        visible: __props.visible,
        class: "signature-modal",
        "onUpdate:visible": _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('update:visible', $event);
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1), _createVNode(_component_SignatureBoard, {
            width: 670,
            height: 450,
            onInit: _cache[0] || (_cache[0] = function ($event) {
              return cData.signatureBoard = $event;
            })
          }), _createVNode(_component_BaseGrape, {
            "align-end": "",
            "single-line-right": "",
            gap: "23px"
          }, {
            default: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", {
                href: "javascript:;",
                class: "",
                onClick: _cache[1] || (_cache[1] = function ($event) {
                  return cData.signatureBoard.clearCanvas();
                })
              }, " 重簽 ")]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_BaseButton, {
                variant: "purple",
                radius: "5px",
                h: "45px",
                fz: "19px",
                style: {
                  "width": "126px"
                },
                onClick: _cache[2] || (_cache[2] = function ($event) {
                  return confirm();
                })
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(" 確認 ")];
                }),
                _: 1
              })])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["visible"]);
    };
  }
};